import React from 'react';
import { Container, Typography, Card, CardContent, Box } from '@mui/material';
import { motion } from 'framer-motion';

const Contact = () => {
  return (
    <Container>
      <Box my={4}>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h4" gutterBottom align="center">
            Контакты
          </Typography>
          <Card sx={{ borderRadius: '10px', boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Связаться с нами:
              </Typography>
              <Typography variant="body1">
                  sanvilab-spb@mail.ru
                  <Typography variant="body1">
              
                  +79214135270
              </Typography>
              </Typography>
            </CardContent>
          </Card>
        </motion.div>
      </Box>
    </Container>
  );
};

export default Contact;
