import React, { useState } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Dialog, DialogTitle, DialogContent, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import product1Image from './images/123.png';
import product2Image from './images/2.jpg';
import product3Image from './images/3.png';
import product4Image from './images/4.jpg';
import product5Image from './images/5.jpg';
import product9Image from './images/9.png';

const products = [
  { 
    name: 'Набор реагентов диагностических жидких для определения антител к антигенам эритроцитов (Тест-эритроциты ID-DiaCell)\nУпаковка ID-DiaCell I-II-III 0,8%', 
    shortDescription: 'Нажмите для более подробных деталей', 
    description: (
      <>
        <Typography variant="body1" fontWeight="bold">
          Назначение:
        </Typography>
        <Typography variant="body1" paragraph>
          проведение скрининга антиэритроцитарных антител в гелевых картах (мануально или с применением автоматических анализаторов). Исследование антител проводится:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              при скрининге антител у доноров и реципиентов;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              при выявлении иммунологических конфликтов мать-плод по антигенам эритроцитов для диагностики гемолитической болезни новорожденных
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" fontWeight="bold">
          Состав упаковки:
        </Typography>
        <Typography variant="body1" paragraph>
          - Тест-эритроциты ID-DiaCell I  (CC<sup>W</sup>Dee) 0,8%, 10мл – 1 флакон<br />
          - Тест-эритроциты ID-DiaCell II (ccDEE) 0,8%, 10мл – 1 флакон<br />
          - Тест-эритроциты ID-DiaCell III (ccddee) 0,8%, 10мл – 1 флакон
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          Срок годности – 8 недель
        </Typography>
      </>
    ),
    image: product3Image 
  },
  { name: 'Набор реагентов диагностических жидких для определения антител к антигенам эритроцитов (Tест-эритроциты ID-DiaCell)  Упаковка ID-DiaCell А и В 0,8%', 
    shortDescription: 'Нажмите для более подробных деталей', 
    description: (
      <>
        <Typography variant="body1" fontWeight="bold">
          Назначение:
        </Typography>
        <Typography variant="body1" paragraph>
          Определение антител анти-А и анти-В в гелевых картах (мануально или с применением автоматических анализаторов). Исследование антител проводится при определении группы крови перекрестным методом (определение анти-А, анти- В антител в сыворотке больного или донора).
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          Состав упаковки:
        </Typography>
        <Typography variant="body1" paragraph>
          - Тест-эритроциты ID-DiaCell А 0,8%, 10мл – 1 флакон<br />
          - Тест-эритроциты ID-DiaCell В 0,8%, 10мл – 1 флакон
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          Срок годности – 8 недель
        </Typography>
      </>
    ), 
    image: product2Image 
  },
  { 
    name: 'Набора реагентов диагностических жидких для определения антител к антигенам эритроцитов (Тест-эритроциты ID-DiaCell) Упаковка О-А-В 5%', 
    shortDescription: 'Нажмите для более подробных деталей', 
    description: (
      <>
        <Typography variant="body1" fontWeight="bold">
          Назначение:
        </Typography>
        <Typography variant="body1" paragraph>
        Тест-эритроциты ID DiaCell О, A, B предназначены для выявления антител анти-А и анти-В. Исследование антител проводится при определении группы крови перекрестным методом (определение анти-А, анти-В антител в сыворотке больного или донора).
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          Состав упаковки:
        </Typography>
        <Typography variant="body1" paragraph>
          - Тест-эритроциты ID DiaCell О 5%, 10мл – 1 флакон<br />
          - Тест-эритроциты ID DiaCell А 5%, 10мл – 1 флакон<br />
          - Тест-эритроциты ID DiaCell В 5%, 10мл – 1 флакон
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          Срок годности – 8 недель
        </Typography>
      </>
    ),        
    image: product4Image 
  },
  { 
    name: 'Набора реагентов диагностических жидких для определения антител к антигенам эритроцитов (Тест-эритроциты ID-DiaCell) Упаковка ID-DiaCell ОRh+ и ОRh- 5%', 
    shortDescription: 'Нажмите для более подробных деталей', 
    description: (
      <>
        <Typography variant="body1" fontWeight="bold">
          Назначение:
        </Typography>
        <Typography variant="body1" paragraph>
        Проведение контрольного исследования при определении резус-принадлежности крови в пробирках без подогрева.
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          Состав упаковки:
        </Typography>
        <Typography variant="body1" paragraph>
          - Тест-эритроциты ID-DiaCell ORh+ 5%, 10мл – 1 флакон<br />
          - Тест-эритроциты ID-DiaCell ORh- 5%, 10мл – 1 флакон<br />
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          Срок годности – 8 недель
        </Typography>
      </>
    ),        
    image: product5Image 
  },
  { 
    name: 'Набор реагентов диагностических жидких для определения антител к антигенам эритроцитов (Тест-эритроциты ID-DiaCell) Упаковка ID-DiaCell I-II-III 5%', 
    shortDescription: 'Нажмите для более подробных деталей', 
    description: (
      <>
        <Typography variant="body1" fontWeight="bold">
          Назначение:
        </Typography>
        <Typography variant="body1" paragraph>
        проведение скрининга и идентификации антиэритроцитарных антител в методе конглютинации с желатином. Исследование антител проводится:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
             при скрининге антител у доноров и реципиентов;
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
             при выявлении иммунологических конфликтов мать-плод по антигенам эритроцитов для диагностики гемолитической болезни новорожденных
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" fontWeight="bold">
          Состав упаковки:
        </Typography>
        <Typography variant="body1" paragraph>
          - Тест-эритроциты ID-DiaCell I (CC<sup>W</sup>Dee) 5%, 10мл – 1 флакон<br />
          - Тест-эритроциты ID-DiaCell II  (ccDEE) 5%, 10мл – 1 флакон<br />
          - Тест-эритроциты ID-DiaCell III (ccddee) 5%, 10мл – 1 флакон
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          Срок годности – 8 недель
        </Typography>
      </>
    ),
    image: product9Image 
  },
];

const Products = ({ onDialogOpen, onDialogClose }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleClickOpen = (product) => {
    setSelectedProduct(product);
    onDialogOpen();
  };

  const handleClose = () => {
    setSelectedProduct(null);
    onDialogClose();
  };

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" gutterBottom align="center">
          Продукция
        </Typography>
        <Grid container spacing={4}>
          {products.map((product, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ borderRadius: '10px', boxShadow: 3 }} onClick={() => handleClickOpen(product)}>
                <CardMedia
                  component="img"
                  height="200"
                  image={product.image}
                  alt={product.name}
                />
                <CardContent>
                  <Typography variant="h6">
                    {product.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Dialog open={Boolean(selectedProduct)} onClose={handleClose}>
          <DialogTitle>
            {selectedProduct?.name}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <CardMedia
              component="img"
              image={selectedProduct?.image}
              alt={selectedProduct?.name}
              style={{ marginBottom: '20px', maxHeight: '300px', objectFit: 'contain' }}
            />
            <Typography variant="body1" paragraph>
              {selectedProduct?.description}
            </Typography>
          </DialogContent>
        </Dialog>
      </Box>
    </Container>
  );
};

export default Products;
