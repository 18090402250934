import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';

import aboutImage from './images/8.jpg';

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${aboutImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  minHeight: '500px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  padding: '20px',
  boxSizing: 'border-box',
  marginTop: theme.spacing(8),
  willChange: 'transform, opacity', // Оптимизация для анимации
}));

const AboutTextBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '20px',
  padding: theme.spacing(4),
  maxWidth: '600px',
  textAlign: 'center',
}));

const About = () => {
  return (
    <Container disableGutters>
      <BackgroundBox>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          style={{ willChange: 'transform, opacity' }} // Оптимизация для анимации
        >
          <AboutTextBox>
            <Typography variant="h4" gutterBottom>
              О нас
            </Typography>
            <Typography variant="h6" gutterBottom>
              ООО "НПП САНВИЛАБ"
            </Typography>
            <Typography variant="body1" paragraph>
              Наша компания занимается разработкой и производством диагностических реагентов. Мы стремимся предоставить нашим клиентам продукцию высокого качества, которая поможет в проведении точных и надежных медицинских исследований.
            </Typography>
            <Typography variant="body1" paragraph>
              Наши ценности включают инновации, качество и клиентский сервис. Мы постоянно работаем над улучшением наших продуктов и услуг, чтобы удовлетворить потребности наших клиентов.
            </Typography>
            <Typography variant="body1">
              Свяжитесь с нами для получения дополнительной информации о нашей продукции и услугах.
            </Typography>
          </AboutTextBox>
        </motion.div>
      </BackgroundBox>
    </Container>
  );
};

export default About;
