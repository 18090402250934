import React, { useState } from 'react';
import { Link as ScrollLink, Element } from 'react-scroll';
import Header from './Header';
import About from './About';
import Products from './Products';
import Contact from './Contact';
import Footer from './Footer';
import { AppBar, Toolbar, Button, Container, CssBaseline, Box, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import logo from './images/6.png'; // Путь к вашему логотипу

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

const App = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleLinkClick = () => {
    handleMenuClose(); // Закрываем меню после клика по ссылке
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!isDialogOpen && (
        <AppBar position="fixed" color="primary" style={{ zIndex: 1400 }}>
          <Toolbar>
            <img src={logo} alt="Logo" style={{ height: 40, marginRight: 20 }} />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              ООО "НПП САНВИЛАБ"
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 2 }}>
              <Button color="inherit">
                <ScrollLink to="about" smooth={true} duration={500} offset={-70} style={{ color: '#ffffff', textDecoration: 'none' }} onClick={handleLinkClick}>О нас</ScrollLink>
              </Button>
              <Button color="inherit">
                <ScrollLink to="products" smooth={true} duration={500} offset={-70} style={{ color: '#ffffff', textDecoration: 'none' }} onClick={handleLinkClick}>Продукция</ScrollLink>
              </Button>
              <Button color="inherit">
                <ScrollLink to="contact" smooth={true} duration={500} offset={-70} style={{ color: '#ffffff', textDecoration: 'none' }} onClick={handleLinkClick}>Контакты</ScrollLink>
              </Button>
            </Box>
            <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuClick}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleMenuClose}>
                  <ScrollLink to="about" smooth={true} duration={500} offset={-70} style={{ color: '#1976d2', textDecoration: 'none' }} onClick={handleLinkClick}>О нас</ScrollLink>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <ScrollLink to="products" smooth={true} duration={500} offset={-70} style={{ color: '#1976d2', textDecoration: 'none' }} onClick={handleLinkClick}>Продукция</ScrollLink>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <ScrollLink to="contact" smooth={true} duration={500} offset={-70} style={{ color: '#1976d2', textDecoration: 'none' }} onClick={handleLinkClick}>Контакты</ScrollLink>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      {!isDialogOpen && <Toolbar />}
      <Container>
        <Box my={2}>
          <Element name="about">
            <About />
          </Element>
        </Box>
        <Box my={2}>
          <Element name="products">
            <Products onDialogOpen={handleDialogOpen} onDialogClose={handleDialogClose} />
          </Element>
        </Box>
        <Box my={2}>
          <Element name="contact">
            <Contact />
          </Element>
        </Box>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default App;
