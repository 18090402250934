import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 2, mt: 4 }}>
      <Container maxWidth="lg">
        <Typography variant="body2" align="center">
          © 2024 ООО "НПП САНВИЛАБ". Все права защищены.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
